<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'BankAccountMaster'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/bank`"
                title="Bank Account"
                :columns="columns"
                routerpath="/master/account/addbankaccount"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import GoodTableColumnSearch from '../../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
import TabPosition from '../../../../components/TabView.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT

export default {
  components: {
    GoodTableColumnSearch,
    TabPosition
  },
  data () {
    return {
      // tabs: [
      //   { name: 'Ledger', route: '/master/accounting/ledger', icon: 'WindIcon' },
      //   { name: 'AccountGroup', route: '/master/accounting/accountgroup', icon: 'UsersIcon' },
      //   { name: 'BankAccountMaster', route: '/master/accounting/bankaccount', icon: 'GridIcon' },
      //   { name: 'BankStatement', route: '/master/accounting/bankstatement', icon: 'FileTextIcon' },
      //   { name: 'Vendor', route: '/master/accounting/vendor', icon: 'SidebarIcon' },
      //   { name: 'Vendortype', route: '/master/accounting/vendortype', icon: 'TypeIcon' },
      //   { name: 'Vendorcategory', route: '/master/accounting/vendorcategory', icon: 'OctagonIcon' },
      //   { name: 'Broker', route: '/master/accounting/broker', icon: 'UserIcon' },
      //   { name: 'Farmer', route: '/master/accounting/farmer', icon: 'FramerIcon' },
      //   { name: 'PurchaseBuyer', route: '/master/accounting/purchasebuyer', icon: 'TriangleIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Bank Account' : 'Add Bank Account'
        }`,
        submitRouterPath: '/master/account',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/bank`,
        getEditValue:`${baseApi}/getbankById`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Voucher NO',
            name: 'voucherno',
            value: '',
            type: 'number',
            placeholder: 'Enter Voucher Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Date',
            name: 'date',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Select date',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Cheque NO',
            name: 'chequeno',
            value: '',
            type: 'number',
            placeholder: 'Enter Cheque Number',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Client Name',
            name: 'clientname',
            value: '',
            type: 'text',
            placeholder: 'Enter Client Name',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Company Name',
            name: 'companyname',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'companyname',
            options: []
            // required: {
            //   required,
            // },
          },
          {
            label: 'Bank Name',
            name: 'bankname',
            value: '',
            type: 'text',
            placeholder: 'Enter Bank Name',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Amount',
            name: 'amount',
            value: '',
            type: 'text',
            placeholder: 'Enter Amount',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Cheque Date',
            name: 'chequedate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Select Cheque date',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Reason',
            name: 'reason',
            value: '',
            type: 'text',
            placeholder: 'Enter Reason',
            class: 'col-md-6'
            // required: {
            //   required,
            // },
          },
          {
            label: 'Client\'s Bank',
            name: 'clientbank',
            value: '',
            type: 'text',
            placeholder: 'Enter Client\'s Bank',
            class: 'col-md-6'
          }
        ]
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Bank Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Bank Name'
          }
        },
        {
          label: 'Branch Name ',
          field: 'bankbranch',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Branch Name'
          }
        },
        {
          label: 'Account No',
          field: 'accountno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Account Name'
          }
        },

        {
          label: 'IFSC Code',
          field: 'ifsccode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search IFSC Code'
          }
        },
        {
          label: 'Enter By',
          field: 'enterby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Modified By',
          field: 'modifiedby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      viewFields:[
        {
          label:'Account Group',
          field: 'accountgrp',
          type: 'dropdown',
          responseValue:'name',
          class:'col-md-4'
        },
        {
          label:'Bank Name',
          field:'bankname',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Branch Name',
          field:'bankbranch',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Account No',
          field:'accountno',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'IFSC Code',
          field:'ifsccode',
          type:'text',
          class:'col-md-4'
        },
      ],
      edit: '',
      add: '',
      canDelete: ''
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Bank Account') {
          this.edit = item.edit
          this.add = item.add
          this.canDelete = item.delete
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push('/')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
